@import "../../global.scss";

.topbar {
    width: 100%;
    height: 80px;
    background-color: white;
    color: $mainColor;
    position: fixed;
    top: 0;
    z-index: 25;
    transition: all 1s ease;

    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;

            .logo {
                width: 65px;
                height: 55px;
                align-items: left;
                margin-right: 50px;
                top: 0;
            }

            .itemContainer {
                display: flex;
                align-items: center;
                margin-right: 30px;
                @include mobile {
                    display: none;
                }

                .icon {
                    font-size: 20px;
                    margin-right: 7px;
                }

                span {
                    font-size: 18px;
                    font-weight: 500;
                    overflow: hidden;
                }

                a {
                    text-decoration: none;
                    color: inherit;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }

        .right {
            .hamburger {
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                span {
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 1s ease;
                }
            }
        }
    }

    &.active {
        background-color: $mainColor;
        color: white;

        .hamburger {
            span {
                &:first-child {
                    background-color: white;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
