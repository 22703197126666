@import "../../global.scss";

.menu {
    width: 230px;
    height: 100%;
    background-color: $mainColor;
    color: white;
    position: fixed;
    top: 0;
    right: -240px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;

    @include mobile {
        width: 100%;
        right: -100%;
    }

    &.active {
        right: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 28px;
        font-weight: 200;
        color: white;
        width: 60%;

        @include mobile {
            font-size: 50px;
            font-weight: 300;
        }

        li {
            margin-bottom: 30px;
            a {
                font-size: 25px;
                color: inherit;
                text-decoration: auto;
            }
        }

        li:hover {
            font-weight: 400;
        }
    }
}
