@import "../../global.scss";

.portfolioList {
    font-size: 14px;
    display: inline;
    padding: 10px 20px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 15px;
    cursor: pointer;

    &.active {
        background-color: $mainColor;
        color: white;
    }
}
