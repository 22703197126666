@import "../../global.scss";

.projects {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .wrapper {
        
        min-height: 100vh;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            height: 70px;
            font-size: 50px;
            overflow: hidden;
            margin-top: 20px;
        }

        ul {
            margin: 14px;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
                font-size: 14px;
                display: inline;
                padding: 10px 20px;
                margin-right: 20px;
                margin-left: 20px;
                border-radius: 15px;
                cursor: pointer;
                overflow: hidden;

                &:hover {
                    background-color: rgb(231, 230, 230);
                }

                &.active {
                    background-color: $mainColor;
                    color: white;
                }
            }

            @include mobile {
                margin: 10px 0;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        .container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 20px;
            overflow-y: auto;

            .item {
                width: 380px;
                height: 320px;
                z-index: inherit;

                border-radius: 10px;
                border: 1px solid lightgray;
                margin: 10px 20px;

                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                position: relative;
                transition: all 0.5s ease;

                .text {
                    position: absolute;
                    width: 90%;
                    top: 8%;
                    object-fit: cover;
                    visibility: hidden;

                    h3 {
                        font-size: 25px;

                        @include mobile {
                            font-size: 22px;
                        }
                    }

                    p {
                        font-size: 15px;

                        @include mobile {
                            font-size: 13px;
                        }
                        .inner {
                            a {
                                font-size: 25px;
                            }
                        }
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                }

                &:hover {               
                    background-color: rgb(43, 42, 42);
                    cursor: pointer;
                    img {
                        opacity: 0.2;
                        z-index: 0;
                    }

                    .text {
                        visibility: visible;
                    }
                }

                @include mobile {
                    background-color: rgb(43, 42, 42);
                    cursor: pointer;
                    img {
                        opacity: 0.2;
                        z-index: 0;
                    }

                    .text {
                        visibility: visible;
                    }
                }

                @include vertical {
                    background-color: rgb(43, 42, 42);
                    cursor: pointer;
                    img {
                        opacity: 0.2;
                        z-index: 0;
                    }

                    .text {
                        visibility: visible;
                    }
                }
            }
        }
    }

    .arrow {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            img {
                padding-top: 20px;
                width: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100% {
                    opacity: 0;
                }
            }
        }
    }
}
