@import "../../global.scss";

.intro {
  background: white;
  display: flex;

  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  height: calc(100vh - 80px);
  

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.4;
    animation: 1s ease-in-out rollIn;
    animation-fill-mode: backwards;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      flex: 0;
      height: 0px;
      width: 0px;
      display: none;
    }

    .imgContainer {
      width: 80%;
      //height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      padding-top: 40px;

      img {
        min-height: 200px;
        min-width: 200px;
        max-width: 300px;
      }
    }
  }

  .right {
    flex: 0.6;
    position: relative;
    animation: 1s ease-in-out rollIn;
    animation-fill-mode: forwards;

    @include mobile {
      flex: 1;
    }

    .wrapper {
      width: 90%;
      height: 90%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        justify-content: center;
        padding-left: 80px;
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;
      }

      h2 {
        font-size: 35px;
        font-family: "Courier Prime";
        padding-top: 50px;
        color: #5a74a8;
      }

      h3 {
        font-size: 35px;
        font-weight: 400;
        span {
          font-weight: 400;
          font-size: inherit;
          color: rgb(172, 28, 57);
          text-decoration: underline;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }
      }
    }

    a {
      position: absolute;
      bottom: 10%;
      left: 43%;

      @include mobile {
        left: 50%;
      }

      img {
        padding-top: 0px;
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
