@import "../../global.scss";
.contact {
    background-color: white;
    min-height: 90vh;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h1 {
        height: 80px;
        font-size: 50px;
        overflow: hidden;
        padding-top: 0px;
        padding-bottom: 70px;
        text-align: center;
        left: 0;
    }

    .wrapper {
        width: 100%;
        min-height: 75vh;
        display: flex;
        @include mobile {
            justify-content: center;
            width: 100%;
        }

        .left {
            flex: 5;
            min-height: 75vh;
            background-color: white;
            @include mobile {
                flex: 0;
            }

            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .right {
            flex: 7;
            min-height: 75vh;

            @include mobile {
                flex: 1;
                margin-left: 7%;
                min-height: 50vh;
            }
            background-color: white;
            Form {
                width: 100%;
                height: 100%;
                align-items: center;
                .ui.input {
                    width: 90%;
                    height: 30px;
                    font-size: 14px;
                }

                textarea {
                    width: 90%;
                    min-height: 250px;
                    font-size: 14px;
                }

                .ui.button {
                    width: 90%;
                    height: 30px;
                    color: white;
                    background-color: crimson;
                    border: none;
                    border-radius: 10px;
                    font-weight: 500;
                    cursor: pointer;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}
