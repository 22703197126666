@import "../../global.scss";

.experience {
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 90vh;
    
    h1 {
        
        z-index: 4;
        color: white;
        font-size: 55px;
        position: absolute;
        top: 2%;

        @include mobile {
            display: none;
        }
    }

    .arrow {
        height: 10%;
        min-height: 30px;
        position: absolute;
        cursor: pointer;

        &.left {
            left: 2.5%;
            transform: rotateY(180deg);
        }

        &.right {
            right: 2.5%;
        }

        @include mobile {
            &.left {
                left: 25%;
                bottom: 5%;
                transform: rotateY(180deg);
                display: auto;
            }

            &.right {
                right: 25%;
                bottom: 5%;
            }
        }

        @include vertical {
            &.left {
                left: 25%;
                bottom: 5%;
                transform: rotateY(180deg);
            }

            &.right {
                right: 25%;
                bottom: 5%;
            }
        }

        &.inactive {
            display: none;
        }
    }

    .slider {
        height: 70vh;
        display: flex;
        position: absolute;
        left: 0;
        top: 15%;
        transition: all 1s ease-out;

        @include mobile {
            top: 5%;
            z-index: 3;
            height: 70vh;
        }

        @include vertical {
            top: 10%;
            z-index: 3;
            height: 70vh;
        }

        .container {
            width: 100vw;
            height: 70vh;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobile {
                height: 70vh;
                min-width: 0px;
            }

            @include vertical {
                height: 60vh;
                min-width: 0px;
            }

            .item {
                width: 70%;
                min-width: 500px;
                max-width: 750px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile {
                    width: 90%;
                    height: 100%;
                    min-width: 0px;
                    flex-direction: column;
                    align-items: flex-start;
                }

                @include vertical {
                    width: 90%;
                    height: 100%;
                    min-width: 0px;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .left {
                    flex: 9;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include mobile {
                        flex: 1;
                    }

                    @include vertical {
                        flex: 0.75;
                        width: 100%;
                    }

                    .leftContainer {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .topLeftContainer {
                            display: flex;
                            flex-direction: row;
                            justify-content: left;
                            align-items: center;
                            width: 90%;
                            margin-top: 20px;
                            margin-bottom: 10px;

                            .imgContainer {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                margin-right: 20px;
                                background-color: rgb(102, 180, 216);
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                @include mobile {
                                    //margin-top: 20px;
                                    width: 30px;
                                    height: 30px;
                                }

                                img {
                                    width: 25px;
                                }
                            }
                            h4 {
                                margin-top: -5px;
                                font-size: 20px;
                                text-align: center;

                                @include mobile {
                                    font-size: 17px;
                                    margin-top: 0px;
                                }
                            }
                        }

                        .text {
                            width: 90%;
                            h2 {
                                font-size: 35px;
                                margin-bottom: 10px;
                                @include mobile {
                                    font-size: 27px;
                                }
                            }

                            h3 {
                                font-size: 25px;
                                margin: 5px;
                                @include mobile {
                                    font-size: 19px;
                                }
                            }

                            p {
                                font-size: 15px;
                                margin: 5px;

                                @include mobile {
                                    font-size: 14px;
                                }
                            }
                        }

                        .labels {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: left;
                            align-items: center;

                            ul {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                list-style-type: none;

                                li {
                                    width: 80px;
                                    height: 55px;
                                    font-size: 12px;

                                    @media (max-height: 680px) {
                                        height: 23px;
                                    }

                                    text-align: center;
                                    text-decoration: none;
                                    padding: 5px;
                                    margin: 5px;
                                    border-radius: 5px;
                                    background-color: rgb(184, 179, 179);
                                }
                            }
                        }
                    }
                }
                .right {
                    border-radius: 0%;
                    flex: 3;

                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    @include mobile {
                        flex: 0 !important;
                        flex-direction: row !important;
                    }

                    @include vertical {
                        flex: 0.45;
                        width: 100%;
                        flex-direction: row;
                    }

                    img {
                        width: 100%;
                        //transform: rotate(-10deg);

                        @include mobile {
                            width: 50%;
                            transform: none;
                        }

                        @include vertical {
                            width: 40%;
                            transform: none;
                        }
                    }
                }
            }
        }
    }
}
