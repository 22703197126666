@import "../../global.scss";

.education {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;

    h1 {
        font-size: 50px;
        padding-bottom: 0px;
        padding-top: 50px;

        @include mobile {
            padding: 20px;
        }
    }

    .container {
        width: 100%;
        height: 90%;
        min-height: 60vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;

        .card {
            width: 280px;
            height: 230px;
            border-radius: 10px;
            margin: 20px;
            box-shadow: 0px 0px 30px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: all 1s ease;
            position: relative;
            background-color: gainsboro;
            overflow: hidden;

            a {
                display: inline-block;
                width: 100%;
                height: 100%;
                object-fit: fill;
                z-index: 10;
                overflow: hidden;
            }

            img {
                width: inherit;
                height: inherit;
                object-fit: fill;
                overflow: hidden;
                z-index: 1;
                opacity: 0.3;
            }

            .course {
                z-index: 2;
                position: absolute;
                font-family: Dosis;
                font-size: 50px;
                display: flex;
                justify-content: center;
                color: white;
                top: 20%;
                h1 {
                    text-align: center;
                }
            }

            &:hover {
                background-color: gainsboro;

                cursor: pointer;
                img {
                    opacity: 0.2;
                    z-index: 1;
                }

                .text {
                    z-index: 2;
                }

                transform: scale(1.1);
            }

            &.featured {
                width: 280px;
                height: 260px;

                overflow: hidden;
            }

            .text {
                position: absolute;
                top: 10%;
                z-index: 2;
                .year {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: "Dosis";
                    font-size: 25px;
                    font-weight: 700;
                    color: $mainColor;
                }

                .university {
                    padding: 10px;
                    border-radius: 10px;
                    font-family: "Trebuchet MS";
                    font-weight: 1000;
                    font-size: 25px;
                    text-align: center;
                }

                .desc {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    h3 {
                        text-align: center;
                        margin-bottom: 5px;
                        font-weight: 400;
                    }

                    h4 {
                        text-align: center;
                        color: rgb(95, 95, 95);
                    }
                }
            }
        }
    }
}
