$mainColor: #15023a;

$width: 700px;
$height: 400px;

@mixin mobile {
    @media (max-width: #{$width}) {
        @content;
    }
}

@mixin vertical {
    @media screen and (orientation:portrait) and (min-width: #{$width}) {
        @content;
    }
}
