.landing {
    height: 100vh;

    .sections {
        width: 100%;
        height: calc(100vh - 80px);
        background-color: white;
        position: relative;
        top: 80px;
        scroll-behavior: smooth;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        > * {
            width: 100vw;
            scroll-snap-align: start;
        }
    }
}
